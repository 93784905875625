import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import InicioCard from "../../../components/InicioCard";
import AAFFIcon from "../../../images/aaff.png"
import ConsultoresIcon from "../../../images/consultores.png"
import TecnicosIcon from "../../../images/tecnicos.png"
import ProveedoresIcon from "../../../images/proveedores.png"
import { firestore } from "../../../providers/firebase";
import firebase from "firebase";
import { ExportToExcel } from "../../../providers/export-excel";

function Inicio() {

  return (<> 
  {/* <ExportToExcel apiData={listaFincas} fileName={"listadoComunidades"} /> */}
    <div style={{
      textAlign: 'center',
      fontSize: 28,
      color: "#303248",
      fontWeight: 'bold',
      marginTop: 90
    }}>Bienvenido a tu gestor de fincas</div>
    <div style={{
      textAlign: 'center',
      fontSize: 22,
      color: "#303248",
      marginTop: 10,
      marginBottom: 70
    }}>¿QUÉ QUIERES HACER?</div>
    <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
      <Grid item><InicioCard icono={AAFFIcon} texto="Gestionar Administradores de Fincas" ruta="/aaff" /></Grid>
      <Grid item><InicioCard icono={ConsultoresIcon} texto="Gestionar Consultores" ruta="/consultores" /></Grid>
      <Grid item><InicioCard icono={ProveedoresIcon} texto="Gestionar proveedores" ruta="/proveedores" /></Grid>
      <Grid item><InicioCard icono={TecnicosIcon} texto="Gestionar Técnicos SPA" ruta="/tecnicos" /></Grid>
      <Grid item><InicioCard icono={TecnicosIcon} texto="Gestionar Comerciales" ruta="/comerciales" /></Grid>
    </Grid>
  </>);
}

export default Inicio;
